import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TermsModalComponent } from '../../../../../../components/terms-modal/terms-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { getEnvironment } from '../../../../../../environment/environment';
import { XtrasService } from '../../../../../../services/xtras.service';

@Component({
  selector: 'app-footer-1',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ]
})


export class Footer1Component implements OnInit {

  currentYear = new Date().getFullYear();
  year?: string;
  title?: string;

  env = getEnvironment();

  line1Images:any[] = [];
  line2Images:any[] = [];
  line3Images:any[] = [];

  line1?: { title: string, titlePos: 'left'|'right'|'center', list: any[] };
  line2?: { title: string, titlePos: 'left'|'right'|'center', list: any[] };
  line3?: { title: string, titlePos: 'left'|'right'|'center', list: any[] };

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public xServices: XtrasService,
  ) {
    if(this.env.type == 'club-bolivar' || this.env.type == 'club-colocolo') {

      if(this.env.type == 'club-bolivar'){
        this.line1Images = [
          { url: 'https://fairplay.com.bo/marcas/puma', img: `assets/img/footer/line-1/puma.png`, name: 'Puma' },
          { url: 'https://www.suzuki.com.bo/', img: `assets/img/footer/line-1/suzuki.png`, name: 'Suzuki' },
          { url: 'https://www.cbn.bo/', img: `assets/img/footer/line-1/pacena.png`, name: 'Paceña' },
          { url: 'https://www.bg.com.bo/', img: `assets/img/footer/line-1/bg.png`, name: 'Banco Ganadero' },
        ];
        this.line2Images = [
          { url: 'https://www.fancesa.com/', img: `assets/img/footer/line-2/fancesa.png`, name: 'Fancesa' },
          { url: 'https://honorbolivia.com/', img: `assets/img/footer/line-2/honor.png`, name: 'Honor' },
          { url: 'https://www.lacascada.com.bo/', img: `assets/img/footer/line-2/villasanta.png`, name: 'Villa Santa' },
          // { url: 'https://yango.com/', img: `assets/img/footer/line-2/yango.png`, name: 'Yango' },
          // { url: 'https://www.pedidosya.com.bo/', img: `assets/img/footer/line-2/pedidosya.png`, name: 'PedidosYa' },
          // { url: 'https://www.tigo.com.bo/', img: `assets/img/footer/line-2/tigo.png`, name: 'Tigo' },
        ];
        this.line3Images = [
          { url: 'https://www.bago.com.bo/', img: `assets/img/footer/line-3/bago.png`, name: 'Bagó' },
          { url: 'https://breick.com.bo/', img: `assets/img/footer/line-3/breick.png`, name: 'breick' },
          { url: 'https://www.facebook.com/OrganicaSRL', img: `assets/img/footer/line-3/sabrositas.png`, name: 'Sabrositas' },
          { url: 'https://ketal.com.bo/', img: `assets/img/footer/line-2/ketal.png`, name: 'Ketal' },
          { url: 'https://libelula.bo/', img: `assets/img/footer/line-3/libelula.png`, name: 'Libélula' },
        ];
  
  
        this.line1 = {
          title: '¿Podemos ayudarte?',
          titlePos: 'left',
          list: [
            { url: '/secure-payment', target: undefined, icon: undefined, name: 'Pago seguro', typeIcon: undefined, action: undefined },
            { url: undefined, target: undefined, icon: undefined, name: 'Términos y condiciones', typeIcon: undefined, action: () => this.openTermsModal() },
          ]
        }
        this.line2 = {
          title: 'Contacto',
          titlePos: 'center',
          list: [
            { url: 'tel:59178831925', target: '_blank', icon: 'fa-light fa-shop', name: 'Bolívar Store: 78831925', typeIcon: 'icon', action: undefined },
            { url: 'https://api.whatsapp.com/send?phone=59176797244', target: '_blank', icon: '/assets/img/footer/whatsapp.png', name: 'WhatsApp: 76797244', typeIcon: 'img', action: undefined },
            { url: 'mailto:contacto@clubbolivar.com', target: '_blank', icon: '/assets/img/footer/mail.png', name: 'E-mail:contacto@clubbolivar.com', typeIcon: 'img', action: undefined },
          ]
        }
        this.line3 = {
          title: 'Mi cuenta',
          titlePos: 'left',
          list: [
            { url: '/account', target: undefined, icon: undefined, name: 'Mi cuenta', typeIcon: undefined, action: undefined },
            { url: '/payments', target: undefined, icon: undefined, name: 'Historal', typeIcon: undefined, action: undefined },
          ]
        }
      }

      if(this.env.type == 'club-colocolo'){
        this.line1Images = [
          { url: 'https://www.milledsports.com/', img: `assets/img/footer/line-1/logo1.png`, name: 'Puma' },
          { url: 'https://www.1991.cl/', img: `assets/img/footer/line-1/logo4.png`, name: 'Suzuki' },
          { url: 'http://www.tto.cl/', img: `assets/img/footer/line-1/logo3.png`, name: 'Paceña' },
          { url: 'https://www.streetmachine.cl/', img: `assets/img/footer/line-1/logo2.png`, name: 'Banco Ganadero' },
        ];
        this.line2Images = [
          { url: 'https://www.3dilution.cl/', img: `assets/img/footer/line-2/logo1.png`, name: 'Fancesa' },
          { url: 'https://www.titoys.cl/', img: `assets/img/footer/line-2/logo2.png`, name: 'Honor' },
          { url: 'https://www.cratus.cl/collections/todo', img: `assets/img/footer/line-2/logo3.png`, name: 'Villa Santa' },
          { url: 'https://www.campeonesdeestampa.cl/', img: `assets/img/footer/line-2/logo4.png`, name: 'Yango' },
          { url: 'https://www.tiendaolymphus.cl/', img: `assets/img/footer/line-2/logo5.png`, name: 'PedidosYa' },
          { url: 'https://www.wonderunlimited.cl/', img: `assets/img/footer/line-2/logo6.png`, name: 'Tigo' },
        ];
        this.line3Images = [
          { url: 'https://www.artefactory.cl/index.html', img: `assets/img/footer/line-3/logo1.png`, name: 'Bagó' },
          { url: 'https://www.tiendakop.cl/', img: `assets/img/footer/line-3/logo2.png`, name: 'breick' },
          { url: 'https://www.lionssportsmedia.com/', img: `assets/img/footer/line-3/logo3.png`, name: 'Sabrositas' },
          { url: 'https://www.colocolo.cl/', img: `assets/img/footer/line-3/logo4.png`, name: 'Ketal' },
          { url: 'https://playmaker.cl/', img: `assets/img/footer/line-3/logo5.png`, name: 'Libélula' },
          { url: 'https://www.topwear.com/', img: `assets/img/footer/line-3/logo6.png`, name: 'Libélula' },
        ];
  
  
        /* this.line1 = {
          title: '¿Podemos ayudarte?',
          titlePos: 'left',
          list: [
            { url: '/secure-payment', target: undefined, icon: undefined, name: 'Pago seguro', typeIcon: undefined, action: undefined },
            { url: undefined, target: undefined, icon: undefined, name: 'Términos y condiciones', typeIcon: undefined, action: () => this.openTermsModal() },
          ]
        }
        this.line2 = {
          title: 'Contacto',
          titlePos: 'center',
          list: [
            { url: 'tel:59178831925', target: '_blank', icon: 'fa-light fa-shop', name: 'Bolívar Store: 78831925', typeIcon: 'icon', action: undefined },
            { url: 'https://api.whatsapp.com/send?phone=59176797244', target: '_blank', icon: '/assets/img/footer/whatsapp.png', name: 'WhatsApp: 76797244', typeIcon: 'img', action: undefined },
            { url: 'mailto:contacto@clubbolivar.com', target: '_blank', icon: '/assets/img/footer/mail.png', name: 'E-mail:contacto@clubbolivar.com', typeIcon: 'img', action: undefined },
          ]
        }
        this.line3 = {
          title: 'Mi cuenta',
          titlePos: 'left',
          list: [
            { url: '/account', target: undefined, icon: undefined, name: 'Mi cuenta', typeIcon: undefined, action: undefined },
            { url: '/payments', target: undefined, icon: undefined, name: 'Historal', typeIcon: undefined, action: undefined },
          ]
        } */
      }




    } else if(this.env.type == 'club-alianzalima') {
      this.line1 = {
        title: '¿Podemos ayudarte?',
        titlePos: 'left',
        list: [
          { url: '/secure-payment', target: undefined, icon: undefined, name: 'Pago seguro', typeIcon: undefined, action: undefined },
          { url: undefined, target: undefined, icon: undefined, name: 'Términos y condiciones', typeIcon: undefined, action: undefined },
        ]
      }
      this.line2 = {
        title: 'Contacto',
        titlePos: 'center',
        list: [
          { url: undefined, target: '_blank', icon: undefined, name: 'Número de celular de tienda', typeIcon: undefined, action: undefined },
          { url: undefined, target: '_blank', icon: undefined, name: 'Número de whatsapp de tienda', typeIcon: undefined, action: undefined },
          { url: undefined, target: '_blank', icon: undefined, name: 'E-mail de contacto', typeIcon: undefined, action: undefined },
        ]
      }
      this.line3 = {
        title: 'Mi cuenta',
        titlePos: 'left',
        list: [
          { url: '/account', target: undefined, icon: undefined, name: 'Mi cuenta', typeIcon: undefined, action: undefined },
          { url: '/payments', target: undefined, icon: undefined, name: 'Historal', typeIcon: undefined, action: undefined },
        ]
      }
    }
  }
    
  ngOnInit() {
    this.year = this.env.yearProyect;
    this.title = this.env.title;
  }

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

  openTermsModal() {
    const dialogRef = this.dialog.open(TermsModalComponent, {
      width: '1200px',
      disableClose: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);

    });
  }

}
