import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { Subscription, Observable } from 'rxjs';
import { XtrasService } from '../../../../../../services/xtras.service';
import { UserModel } from '../../../../../../services/user-model';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonCapYeiComponent } from '../../../../../../components/button/button.component';
import { DropdownMenuCapYeiComponent } from '../../../../../../components/dropdown-menu/drowdown-menu.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { getEnvironment } from '../../../../../../environment/environment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormatTimePipe } from '../../../../../../pipes/format-time';
import { AlertService } from '../../../../../../services/alert.service';
import { LoadingService } from '../../../../../../services/loading.service';
import { HttpClientProvider } from '../../../../../../services/http-client';
import { SettingsProvider } from '../../../../../../services/settings';
import { NotificationService } from '../../../../../../services/notification.service';
import { CartService, CartType } from '../../../../../../services/cart.service';
import { CountdownService } from '../../../../../../services/countdown.service';

@Component({
  selector: 'app-cart-1',
  templateUrl: './cart.component.html',
  standalone: true,
	imports: [
    CommonModule, 
    RouterModule, 
    MatMenuModule,
    ButtonCapYeiComponent,
    DropdownMenuCapYeiComponent,
    LazyLoadImageModule,
    MatTooltipModule,
    FormatTimePipe,
  ],
  styles: [':host { display: contents; }'],
})
export class Cart1Component implements OnInit {

  private unsubscribe: Subscription[] = [];

  env = getEnvironment();

  loadingCart:boolean = false;

  countdownTime?: string;

  cart$: Observable<CartType>;


  constructor(
    public xServices: XtrasService,
    public userModel: UserModel,
    private alert: AlertService,
    private load: LoadingService,
    private http: HttpClientProvider,
    private toast: NotificationService,
    private cartService: CartService,
    private router: Router,
    private countdownService: CountdownService,
  ) {
    this.cart$ = this.cartService.cart$;
    this.cart$.subscribe(data => {
      if (data) {
        this.countdownService.startCountdown(data.cart.expiration_time ?? '00:00:00');
      }
    })
  }

  ngOnInit() {
    this.countdownService.countdownTime$.subscribe(time => this.countdownTime = time);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  removeItemCart(id: any, quantity:number, name: string) {
    this.alert.alertCapYei({
      type: 'warning',
      message: 'Eliminar Entradas',
      buttons: {
        confirm: { show: true, text: 'Confirmar' },
      },
      description: `¿Está seguro de eliminar (${quantity}) entrada(s) "${name}" de su reservación?`
    }).then(data => {
      if(data.isConfirmed) {
        this.load.loadingShow();
        this.http.getRequest(SettingsProvider.getUrl(`delete-sale-item/${id}`)).subscribe({
          next:(result:any) => {
            this.load.dismiss();
            this.cartService.getUserCart().subscribe();
            if(result['status']) {
            } else {
              this.xServices.getErrorApi(result, true, '/', 'Hubo un error al limpiar el carrito, intente nuevamente.');
            }
          },
          error: (error: any) => {
            this.load.dismiss();
            this.cartService.getUserCart().subscribe();
            this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
          }
        });
      }
    });
  }

  cleanCart(id: any) {
    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl(`delete-sale/${id}`)).subscribe({
      next:(result:any) => {
        this.load.dismiss();
        this.cartService.getUserCart().subscribe();
        if(result['status']) {
        } else {
          this.xServices.getErrorApi(result, true, '/', 'Hubo un error al limpiar el carrito, intente nuevamente.');
        }
      },
      error: (error: any) => {
        this.load.dismiss();
        this.cartService.getUserCart().subscribe();
        this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      }
    });
  }
  
  toPay(id: any) {
    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl(`confirm-sale-cart/${id}`)).subscribe({
      next:(result:any) => {
        if(result['status']&&result['data']&&result['data']['id']) {
          this.load.dismiss();
          this.closeCart();
          this.router.navigateByUrl(`process-cart-sale/${result['data']['id']}`);
        } else {
          this.load.dismiss();
          this.xServices.getErrorApi(result, true, '/', 'Hubo un error al limpiar el carrito, intente nuevamente.');
        }
      },
      error: (error: any) => {
        this.load.dismiss();
        this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      }
    });
  }

  closeCart() {
    let cart = document.getElementById('cart__content');
    if(cart) {
      cart.classList.remove('active');
    } else {
      alert('No existe el componente cart');
    }
  }
}
