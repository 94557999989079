<div id="cart__content" class="cart__content content__ticketing">
	<div class="cart__content-header">
		<button-cy (onPress)="closeCart()" icon="fal fa-times" [iconOnly]="true" />
		<h3>Carrito de Compras</h3>
	</div>
	<ng-container *ngIf="cart$ | async as _data">		
		<div class="cart__ticketing-section">
			<input type="hidden" class="mt-0 mb-[auto] hidden" hidden>
			<div class="content__cart w-full bg-white self-start p-4 pt-6" [ngClass]="{'mt-0 mb-[auto]': (_data.cart && xServices.lengthItems((_data.cart?.sale_items ?? [])))}">
				@if (_data.cart && (_data.cart?.sale_items ?? []).length > 0) {
					<div class="h-2 w-full"></div>
					<hr>
					<div class="h-2 w-full"></div>
					<div class="cart__countdown">
						<h5>
							<span>{{ (countdownTime ?? '00:00:00')  }}</span> <span class="cart__countdown-info" [matTooltip]="'Tienes '+((countdownTime ?? '00:00:00') | formatTime)+' para realizar la reserva.'" matTooltipPosition="above"><img src="/assets/img/general/icon-help.svg" alt="Help"></span>
						</h5>
					</div>
					<div class="h-2 w-full"></div>
					<h5 class="cart__title-ticketing"><span>Lista de Productos</span></h5>
					<div class="h-2 w-full"></div>
				}
				
				<div class="cart__ticketing">
					@if (loadingCart) {
						<div class="no__data-cart">
							<div class="w-[80px] block m-auto">
								<div class="loading-roller"><div *ngFor="let itemLoading of [1,2,3,4,5,6,7,8]"></div></div>
							</div>
						</div>
					} @else {
						@if (_data.cart && xServices.lengthItems((_data.cart?.sale_items ?? []))) {
							@for (item of (_data.cart?.sale_items ?? []); track $index) {
								<div class="cart__item">
									<div class="cart__item-image">
										<img #imgSectorTicket [src]="item?.image ?? env.imgLogoSVG" (error)="imgSectorTicket.src = env.imgLogoSVG" alt="Product">
										<div class="cart__item-delete">
											<button-cy addClass="btn__fit btn__error btn__icon-rounded" (onPress)="removeItemCart(item.id, (item?.quantity ?? 0), (item?.sector_name ?? 'Sin definir'))" icon="fa-light fa-trash" [iconOnly]="true" label="Eliminar entrada(s)" />
										</div>
									</div>
									<div class="cart__item-detail">
										<h4>{{ item?.name ?? 'Sin definir' }}</h4>
										<h5>{{ item.currency ?? 'Bs.' }} {{ item?.price ?? 0 }}</h5>
									</div>
									<div class="cart__item-quantity">
										<span class="cart__item-quantity-x">x</span>{{ item?.quantity ?? 0 }}
									</div>
								</div>
							}
							<div class="w-full bg-white sticky bottom-0 left-0">
								<div class="cart__total">
									<h5><b>Total: </b></h5>
									<h5>{{ _data.cart.currency ?? 'Bs.' }} {{ _data.cart?.amount ?? 0 }}</h5>
								</div>
							</div>
						} @else {
							<div class="no__data-cart">
								<div class="no__data-cart-circle-icon">
									<img src="assets/img/general/ticket.svg" alt="Ticket">
								</div>
								<div class="h-2 w-full"></div>
								<h5 class="no__data-cart-title">Añade tus productos</h5>
								<p class="no__data-cart-desc" [innerHTML]="'Añade los productos que quieres comprar y haz clic en el botón <b>“Añadir al carrito”</b> para empezar con tu compra.'"></p>
							</div>
						}
					}
				</div>
			</div>
		</div>
	
		@if (_data.cart && (_data.cart?.sale_items ?? []).length > 0) {
			<div class="cart__content-footer">
				<div class="grid sm:grid-cols-2 grid-cols-1 gap-2">
					<button-cy (onPress)="cleanCart(_data.cart?.id)" label="Limpiar carrito" addClass="w-full btn__outline small__btn btn_clean-cart !px-3" />
					<button-cy (onPress)="toPay(_data.cart?.id)" label="Continuar" addClass="w-full btn__second small__btn btn_pay-cart !px-3" />
				</div>
			</div>
		}
	</ng-container>
</div>